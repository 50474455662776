import React from "react";
import PropTypes from "prop-types";
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";
import Img from "gatsby-image";

import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Typography from "root/components/Typography";
import Section from "root/components/Section";

import Header from "root/sections/Header";
import HomePerfectFor from "root/sections/HomePerfectFor";
import Footer from "root/sections/Footer";

import linkedin from "root/assets/linkedin.svg";
import twitter from "root/assets/twitter.svg";

import mdxStyles from "root/styles/mdx.module.css";
import styles from "./index.module.css";

function BlogPost({ pageContext }) {
  const { frontmatter, body } = pageContext.blogPost;
  const author = frontmatter.author.childAuthorsJson;

  let currentPath = `https://drivit.com/blog/${frontmatter.path}`;

  if (typeof window !== `undefined`) {
    currentPath = window.location.href;
  }

  return (
    <div>
      <SEO title="Drivit" />
      <Layout>
        <Header currentPage="blog" background="lightGrey" />

        <div className={styles.header}>
          <div className={styles.headerBox}>
            <div className={styles.headerContent}>
              <div className={styles.category}>
                <Typography variant="smallBody" weight="bold" color="green">
                  {frontmatter.category}
                </Typography>
              </div>
              <div className={styles.title}>
                <Typography variant="h1" weight="bold">
                  {frontmatter.title}
                </Typography>
              </div>

              <Img
                className={styles.banner}
                alt="feature"
                fluid={frontmatter.featuredImage.image.fluid}
                objectFit="cover"
              />
            </div>
          </div>
        </div>

        <Section>
          <article className={styles.article}>
            <div className={styles.author}>
              <Img
                key={frontmatter.path}
                className={styles.authorImg}
                alt="author"
                fluid={author.photo.image.fluid}
                objectFit="cover"
              />
              <div className={styles.authorText}>
                <Typography color="darkGrey" weight="bold">
                  <span>By </span>
                  {author.name}
                </Typography>
                <Typography color="darkGrey">
                  {frontmatter.date}
                  <span> · </span>
                  {frontmatter.time}
                  <span> min read</span>
                </Typography>
              </div>
            </div>

            <div className={mdxStyles.content}>
              <div className={styles.socialLinks}>
                <a
                  href={`http://www.linkedin.com/shareArticle?mini=true&url=${currentPath}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    className={styles.linkedinIcon}
                    src={linkedin}
                    alt="linkedin icon"
                  />
                </a>
                <a
                  href={`https://twitter.com/share?url=${currentPath}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    className={styles.twitterIcon}
                    src={twitter}
                    alt="twitter icon"
                  />
                </a>
              </div>

              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </article>
        </Section>
        <HomePerfectFor />
        <Footer />
      </Layout>
    </div>
  );
}

BlogPost.propTypes = {
  pageContext: PropTypes.shape({
    blogPost: PropTypes.shape({
      frontmatter: PropTypes.shape({
        path: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        time: PropTypes.number.isRequired,
        featuredImage: PropTypes.object.isRequired,
        author: PropTypes.shape({
          childAuthorsJson: PropTypes.shape({
            name: PropTypes.string.isRequired,
            position: PropTypes.string.isRequired,
            photo: PropTypes.shape({
              image: PropTypes.shape({
                fluid: PropTypes.shape({}).isRequired,
              }).isRequired,
            }).isRequired,
            twitter: PropTypes.string,
            twitterid: PropTypes.string,
          }).isRequired,
        }).isRequired,
      }).isRequired,
      body: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default BlogPost;
